
import {
  ref, defineComponent, computed,
} from 'vue';
import { mdiBitcoin, mdiContentCopy, mdiChevronDown } from '@mdi/js';
import * as constants from '@/common/store/constants';
import { BtcAccount, WalletAddress } from '@/common/types/pegInTx';
import EnvironmentContextProviderService from '@/common/providers/EnvironmentContextProvider';
import { useAction, useGetter, useStateAttribute } from '@/common/store/helper';
import { AccountBalance, BtcWallet, SatoshiBig } from '@/common/types';
import { WalletService } from '@/common/services';
import { truncateString } from '@/common/utils';

export default defineComponent({
  name: 'PegInAccountSelect',
  setup(_, context) {
    const environmentContext = EnvironmentContextProviderService.getEnvironmentContext();
    const selectedAccountType = ref();
    const bitcoinWallet = useStateAttribute<BtcWallet>('pegInTx', 'bitcoinWallet');
    const balances = useStateAttribute<AccountBalance>('pegInTx', 'balances');
    const loadingBalance = useStateAttribute<boolean>('pegInTx', 'loadingBalance');
    const walletService = useStateAttribute<WalletService>('pegInTx', 'walletService');
    const stateSelectedAccount = useStateAttribute<BtcAccount>('pegInTx', 'selectedAccount');
    const addressList = useStateAttribute<WalletAddress[]>('pegInTx', 'addressList');
    const selectAccount = useAction('pegInTx', constants.PEGIN_TX_SELECT_ACCOUNT_TYPE);
    const calculateTxFee = useAction('pegInTx', constants.PEGIN_TX_CALCULATE_TX_FEE);
    const selectedAccountBalance = useGetter<SatoshiBig>('pegInTx', constants.PEGIN_TX_GET_SELECTED_BALANCE);

    function accountChanged(account: BtcAccount) {
      selectedAccountType.value = account;
      selectAccount(account);
      calculateTxFee();
      context.emit('accountChanged', account);
    }

    const balancesPerAccountType = computed(() => ([
      {
        title: balances.value.legacy.toBTCTrimmedString(),
        value: BtcAccount.BITCOIN_LEGACY_ADDRESS,
        appendText: 'Legacy',
        appendColor: 'pink',
      },
      {
        title: balances.value.segwit.toBTCTrimmedString(),
        value: BtcAccount.BITCOIN_SEGWIT_ADDRESS,
        appendText: 'Segwit',
        appendColor: 'orange',
      },
      {
        title: balances.value.nativeSegwit.toBTCTrimmedString(),
        value: BtcAccount.BITCOIN_NATIVE_SEGWIT_ADDRESS,
        appendText: 'Native Segwit',
        appendColor: 'teal',
      },
    ]));

    const singleAccountType = computed(() => {
      if (walletService.value) {
        return walletService.value.availableAccounts().length === 1
          ? walletService.value.availableAccounts()[0] : '';
      }
      return '';
    });

    const selectedAccountTypeBadge = computed(() => {
      const selected = balancesPerAccountType.value
        .find(({ value }) => value === selectedAccountType.value);
      return { color: selected?.appendColor, text: selected?.appendText };
    });

    const firstBtcAddress = computed(() => addressList.value[0]?.address);

    const walletInfo = computed(() => (!singleAccountType.value
      ? walletService.value.name().formal_name : truncateString(firstBtcAddress.value)));

    function setSelectedAccount(account: string) {
      selectedAccountType.value = account;
    }

    function copyFullAccountAddress() {
      navigator.clipboard.writeText(firstBtcAddress.value);
    }

    if (singleAccountType.value) {
      selectedAccountType.value = singleAccountType.value;
    } else {
      const [firstAccount] = walletService.value.availableAccounts();
      selectedAccountType.value = firstAccount;
    }
    selectAccount(selectedAccountType.value);
    calculateTxFee();

    if (stateSelectedAccount.value) {
      selectedAccountType.value = stateSelectedAccount.value;
    }
    const tooltipText = 'Listed amounts represent the balance using addresses from your first account including change.';

    return {
      singleAccountType,
      environmentContext,
      selectedAccountType,
      loadingBalance,
      mdiContentCopy,
      balances,
      mdiBitcoin,
      balancesPerAccountType,
      selectedAccountTypeBadge,
      tooltipText,
      setSelectedAccount,
      bitcoinWallet,
      walletService,
      selectedAccountBalance,
      accountChanged,
      mdiChevronDown,
      walletInfo,
      copyFullAccountAddress,
    };
  },
});
