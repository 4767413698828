import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "align-center text-no-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center justify-end ga-4"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-center justify-end h-100 w-100 pa-0"
}
const _hoisted_4 = { class: "text-h6 mx-1" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-center ga-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "7",
        class: "d-flex align-center justify-end"
      }, {
        default: _withCtx(() => [
          (_ctx.singleAccountType)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                variant: "text",
                size: "small",
                density: "compact",
                rounded: "full",
                icon: _ctx.mdiContentCopy,
                onClick: _ctx.copyFullAccountAddress
              }, null, 8, ["icon", "onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.walletInfo) + " | " + _toDisplayString(_ctx.selectedAccountBalance.toBTCTrimmedString()) + " " + _toDisplayString(_ctx.environmentContext.getBtcTicker()) + " ", 1),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectedAccountBalance.toBTCString()) + " " + _toDisplayString(_ctx.environmentContext.getBtcTicker()), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        cols: "5",
        class: "pa-0 d-flex align-center"
      }, {
        default: _withCtx(() => [
          (_ctx.singleAccountType)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_v_btn, _mergeProps(_ctx.props, {
                  variant: "plain",
                  class: "h-100"
                }), {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_v_chip, {
                      variant: "flat",
                      color: _ctx.selectedAccountTypeBadge.color,
                      density: "compact"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.selectedAccountTypeBadge.text), 1)
                      ]),
                      _: 1
                    }, 8, ["color"]), [
                      [_vShow, _ctx.selectedAccountType]
                    ])
                  ]),
                  _: 1
                }, 16)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_v_menu, {
                  location: "bottom",
                  "no-click-animation": "true"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, {
                      variant: "plain",
                      class: "h-100"
                    }), {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_v_chip, {
                          variant: "flat",
                          color: _ctx.selectedAccountTypeBadge.color,
                          density: "compact"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedAccountTypeBadge.text), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]), [
                          [_vShow, _ctx.selectedAccountType]
                        ])
                      ]),
                      append: _withCtx(() => [
                        _createVNode(_component_v_icon, { icon: _ctx.mdiChevronDown }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.balancesPerAccountType, (item, index) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: index,
                            value: item.value,
                            onClick: ($event: any) => (_ctx.accountChanged(item.value))
                          }, {
                            title: _withCtx(() => [
                              _createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1)
                            ]),
                            append: _withCtx(() => [
                              _createVNode(_component_v_chip, {
                                tag: "span",
                                variant: "flat",
                                color: item.appendColor,
                                density: "compact",
                                class: "mx-3"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.appendText), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"])
                            ]),
                            _: 2
                          }, 1032, ["value", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.loadingBalance)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_v_progress_circular, {
                        size: "small",
                        indeterminate: "",
                        color: "bw-500"
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}